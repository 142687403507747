import { Section } from "./HomeStyles"
import styled from "styled-components"

export const BlogSection = styled(Section)`
  height: fit-content;
  padding: 2rem 0;
  color: var(--darkBlue);
  min-height: 70vh;
  width: fit-content;

  @media (min-width: 601px) and (max-width: 767px) {
    width: 90%;
    max-width: 500px;

    margin: 0 auto;
  }

  @media (min-width: 1500px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    height: fit-content;

    .grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
    }
    // padding: 1rem;
  }

  .grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    justify-items: center;
    align-items: center;
    display: grid;
    grid-gap: 2rem;
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    overflow: hidden;

    .empty-img{
      background: var(--beige);
      height: 35vh
    }
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    @media (max-width: 769px) {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
  }

  a {
    text-decoration: none;
    color: var(--darkBlue);
    // background-color: var(--white);
  }

  .blog-content {
    overflow: hidden;
    height: 30%;
  }

  article {
    // border-bottom: 1px solid rgba(0, 48, 87, 0.5);
    border-opacity: 0.5;
    margin: 1rem;
    padding-bottom: 1rem;
    & p {
      font-size: 0.7rem;
      margin: 0;
    }

    & h3 {
      margin-bottom: 20px;
      margin-top: 0;
    }
  }
`
