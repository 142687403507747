import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogSection } from "../styles/BlogStyles"
import BodyWrapper from "../templates/BodyWrapper"

export const query = graphql`
  query MyQuery {
    articles: allMediumFeed {
      edges {
        node {
          author
          id
          link
          date(fromNow: true)
          slug
          thumbnail
          title
          content
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const articles = data.articles.edges
    .map(({ node }) => node)
    .filter((el) => el.thumbnail)

  return (
    <Layout>
      <SEO title="Blog" />
      <div style={{background:"var(--beige)"}}>
      <BodyWrapper>
        <BlogSection color={"var(--darkBlue)"} bg={"var(--beige)"}>
          <div className="grid">
            {articles.map((post) => (
              <Link className="card" to={`/blog/${post.slug}`}>
                {post.thumbnail.includes('cdn-images') ? 
                <img
                  src={post.thumbnail}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "35vh",
                    margin: "0",
                    objectPosition: "top",
                  }}
                  alt={post.thumbnail}
                />
              :
              <div class="empty-img">
                </div>}
                <article className="container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px 0",
                    }}
                  >
                    <p>{post.author}</p>
                    <p>Posted {post.date}</p>
                  </div>
                  <h3>{post.title}</h3>
                  <p className="blog-content">
                    {post.content
                      .replace(/(<([^>]+)>)/gi, " ")
                      .substring(0, 150)}
                    ...
                  </p>
                </article>
                {/* </a> */}
              </Link>
            ))}
          </div>
        </BlogSection>
      </BodyWrapper>
      </div>
    </Layout>
  )
}

export default Blog
